<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a"
            >开课记录</a
          >
          <i>></i>
          <a href="javascript:;" class="cur-a">设置上传照片</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">

            <div title="证件类型" class="searchboxItem ci-full">
              <span class="itemLabel">证件类型:</span>
              <el-select
                  v-model="certificatesType"
                  placeholder="请选择"
                  size="small"
                  clearable
              >
                <el-option
                    v-for="item in certificatesTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>
<!--              CERTIFICATES_TYPE-->
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
              >
              <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="addModel()"
              >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              row-key="courseOutlineId"
              :header-cell-style="tableHeader"
              stripe
            >

              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
                  fixed
              />
              <el-table-column
                label="证件名称"
                align="left"
                show-overflow-tooltip
                prop="certificatesName"
                min-width="150"
              />
              <el-table-column
                label="证件类型"
                align="left"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  <span>{{
                      $setDictionary(
                          "CERTIFICATES_TYPE",
                          scope.row.certificatesType
                      )
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="示例图片"
                  align="left"
                  show-overflow-tooltip
                  prop="list"
                  min-width="150"
              >
                <template slot-scope="scope">
                  <span>
                    <img style="padding: 0 8px;cursor: default" :src="item.photoUrl" :alt="item.photoName" width="80" v-for="item in scope.row.list" />
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                  label="注意事项"
                  align="left"
                  show-overflow-tooltip
                  prop="matterAttention"
                  min-width="150"
              />
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="addModel(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="changeEnabled(scope.row)"
                    >{{scope.row.enabled?'禁用':'启用'}}</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="设置上传照片"
      :visible.sync="dialogFormVisible"
      width="35%"
      @close="closeModel"
    >
      <el-form
        ref="groupForm"
        :model="groupForm"
        style="width: 100%"
        :rules="rules"
        label-width="100px"
      >
        <el-form-item label="证件名称" prop="certificatesName">
          <el-input v-model="groupForm.certificatesName" size="small" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="证件类型" prop="certificatesType">
          <el-select v-model="groupForm.certificatesType" placeholder="请选择" size="small">
            <el-option
                v-for="item in certificatesTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="上传图片"
            prop="list"
            class="form-item"
        >
          <el-upload
              class="upload-btndrag"
              :action="actionUrl"
              :on-error="handleError"
              :on-success="handleSuccess"
              :on-change="uploadChange"
              :show-file-list="false"
              :auto-upload="false"
              drag
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
          <div
              v-if="groupForm.list.length"
              style="margin-top: 10px; margin-left: 10px"
          >
            当前选择文件：
            <div style="display: flex; flex-direction: column">
                    <span
                        style="color: #409eff"
                        v-for="(item, index) in groupForm.list"
                        :key="index"
                    >
                      {{ item.photoName }}
                      <a
                          @click="lookExl(item)"
                          style="color: #409eff; margin: 0 15px"
                      >预览</a
                      >
                      <a
                          @click="reomveExlUpload(index)"
                          style="color: red; margin: 0 15px"
                      >删除</a
                      >
                    </span>
            </div>
          </div>
          <div v-else>
            <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
          </div>
        </el-form-item>
        <el-form-item label="注意事项" prop="matterAttention">
          <el-input type="textarea" :rows="3" placeholder="请输入注意事项" v-model="groupForm.matterAttention" style="width: 100%" maxlength="200" show-word-limit></el-input>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="closeModel">取 消</el-button>
        <el-button class="bgc-bv" type="primary" @click="doOk">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 预览附件 - loading -->
    <el-dialog
        title="预览"
        :visible.sync="previewLoding1"
        @close="uploadLodClose"
        width="50%"
        top="2%"
        center
    >
      <div class="ovy-a">
        <div
            id="pdf-cert1"
            style="height: 600px"
            v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import UploadPic from "@/mixins/UploadPic";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
import pdf from "pdfobject";
export default {
  name: "evaluate/SubsidyStandardSetting",
  components: {
    Empty,
    PageNum,
  },
  mixins: [UploadPic,List],
  data() {
    return {
      courseName: this.$route.query.courseName,
      projectId: this.$route.query.projectId,
      projectCourseId: this.$route.query.projectCourseId,

      certificatesType: '', //证件类型
      certificatesTypeList: [],

      previewLoding1: false,

      courseOutlineIds: [],
      multipleTable: [],
      groupForm: {
        projectCertificatesId: '',
        certificatesName: '',
        certificatesType: '10',
        list: [],
        matterAttention: '',
      },
      dialogFormVisible: false, //弹框
      rules: {
        certificatesName: [
          { required: true, trigger: "blur", message: "证件名称不能为空" },
        ],
        certificatesType: [
          { required: true, trigger: "change", message: "证件类型不能为空" },
        ],
      },
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
    this.getCertificatesType()
  },
  methods: {
    //获取证件类型--码值
    getCertificatesType() {
      const certificatesType = this.$setDictionary("CERTIFICATES_TYPE", "list");
      console.log(certificatesType)
      for (const key in certificatesType) {
        this.certificatesTypeList.push({
          value: key,
          label: certificatesType[key],
        });
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.projectId,
        projectCourseId: this.projectCourseId
      };
      if(this.certificatesType){
        params.certificatesType = this.certificatesType
      }
      this.doFetch({
        url: "/projectCourseCertificates/pageList",
        params,
        pageNum,
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight + 4;
    },
    //弹框打开
    addModel(row) {
      this.dialogFormVisible = true;
      this.$nextTick(()=>{

        this.$refs["groupForm"].resetFields();
        this.groupForm.projectCertificatesId = ''
        if(row){
          this.groupForm = {
            ...row
          }
        }
      })



    },
    //弹框关闭
    closeModel() {
      this.$refs["groupForm"].resetFields();
      this.dialogFormVisible = false;
    },
    //弹框确定
    doOk() {
      this.$refs["groupForm"].validate((valid) => {
        if (valid) {
          let params = {
            projectId: this.projectId,
            projectCourseId: this.projectCourseId,
            certificatesName: this.groupForm.certificatesName,
            certificatesType: this.groupForm.certificatesType,
          };
          if(this.groupForm.list.length> 0){
            params.list = this.groupForm.list
          }
          if(this.groupForm.matterAttention){
            params.matterAttention = this.groupForm.matterAttention
          }
          let url = ''
          if(this.groupForm.projectCertificatesId != ''){
            params.projectCertificatesId = this.groupForm.projectCertificatesId
            url = '/projectCourseCertificates/modify'
          }else{
            url = '/projectCourseCertificates/insert'
          }
          console.log(params)
          this.$post(
            url,
            params
          ).then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: ret.message,
                type: "success",
              });

              this.closeModel();
              this.getData(-1);
            }else{
              this.$message({
                message: ret.message,
                type: "error",
              });
            }
          });
        }
      });
    },
    //禁用启用状态更改
    changeEnabled(row) {
      let str = ''
      if(row.enabled){
        str = '禁用'
      }else{
        str = '启用'
      }
      this.$confirm("是否"+ str +"此证件？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let params = {
              enabled: !row.enabled,
              projectId: this.projectId,
              projectCertificatesId: row.projectCertificatesId
            }
            this.$post("/projectCourseCertificates/changeEnabled", params).then((res) => {
              if (res.status == "0") {
                this.$message({
                  type: "success",
                  message: str+"成功!",
                });
                this.getData(-1);
              }else{
                this.$message({
                  message: res.message,
                  type: "error",
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    // 上传图片
    uploadChange(file) {
      if(this.groupForm.list.length == 3){
        this.$message({
          type: "error",
          message: "最多上传三张图片",
        });
        return
      }
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      const jpg = extension === "jpg";
      const png = extension === "png";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !jpg && !png) {
        this.$message.error("只能上传后缀是.pdf或.PDF或.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.groupForm.list.push({
              photoName: file.name,
              photoKey: result.data.fileKey,
              photoType: extension,
              photoUrl: result.data.fileURL,
            });
          })
          .catch((err) => {
            return;
          });
    },
    // 删除图片
    reomveExlUpload(index) {
      this.groupForm.list.splice(index, 1);
      this.$forceUpdate();
    },
    // 预览图片
    lookExl(item) {
      this.previewLoding1 = true;
      this.fileType = item.photoType;
      if (item.photoType == "pdf" || item.photoType == "PDF") {
        console.log(1);
        setTimeout(() => {
          pdf.embed(item.photoUrl, "#pdf-cert1");
        }, 300);
      } else {
        this.ImgSrc = item.photoUrl;
      }
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function (route) {
        if (route.name != "evaluate/SubsidyStandardSetting") return;
        if (route.params.refresh) {
          if (route.params.isAdd) {
            this.params = {};
            this.getData();
          } else {
            this.getData(-1);
          }
        } else if (sessionStorage.getItem("refresh") != 0) {
          sessionStorage.setItem("refresh", 0);

          this.params = {};
          this.getData();
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.searchbox > div .el-input {
  flex: 1;
}
.projectUser {
  min-width: 6rem !important;
}
.qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 400px;
    height: 400px;
    background-color: #999; //设置白色背景色
  }
}
.refreshcode {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 1rem; */
  padding-top: 1rem;
}
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 23px;
  width: 60px;
  display: block;
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
</style>
<style lang="less">
.no_border_upload {
  .el-upload.el-upload--text {
    border: 0;
    height: auto !important;
    margin: 0 8px;
  }
}
</style>
